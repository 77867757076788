<template>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        ref="table"
        title="검진소견 목록"
        tableId="table"
        :columns="grid.columns"
        :gridHeight="grid.height"
        :merge="grid.merge"
        :data="grid.data"
        :filtering="true"
        :columnSetting="false"
        :usePaging="false"
        :isExcelDown="false"
        :isFullScreen="false"
        :expandAll="true"
        rowKey="heaCheckupOpinionId"
        @linkClick="linkClick"
      >
        <!-- 버튼 영역 -->
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="검진소견서 업로드" icon="upload"  @btnClicked="openExcelUploader"/>
            <c-btn label="전체 삭제" icon="delete_forever" @btnClicked="removeAll" />
          </q-btn-group>
        </template>
        <template slot="table-chip">
          <font color="#C10015">
            ※ 추가로 엑셀업로드 시 기존데이터에 결과가 추가됩니다(중복 허용). 다시 업로드 하고 싶으면 전체 삭제 후 진행하세요.
          </font>
        </template>
      </c-table>
      <c-dialog :param="popupOptions" :isConfirm="true"></c-dialog>
    </div>
  </template>
  <script>
  import { uid } from 'quasar'
  import selectConfig from '@/js/selectConfig';
  import transactionConfig from '@/js/transactionConfig';
  export default {
    name: 'check-up-opinion',
    props: {
      popupParam: {
        type: Object,
        default: () => ({
          heaCheckupPlanId: '',
        }),
      },
      checkUp: {
        type: Object,
        default: () => ({
          heaCheckupPlanId: '',
          plantCd: '',
          checkupName: '',
          checkupTypeCd: null,
          checkupStartDate: '',
          checkupEndDate: '',
          regUserId: '',
          chgUserId: '',
          uploadFlag: 'N',
  
          // prototype
          hospitalId: '',
          hospitalLocation: '',
          hospitalName: '',
          typeFlag: '',
        }),
      },
      contentHeight: null,
    },
    data() {
      return {
        editable: false,
        grid: {
          merge: [
            { index: 0, colName: 'upDeptCd' },
            { index: 1, colName: 'deptCd' },
          ],
          columns: [
            {
              name: 'upDeptName',
              field: 'upDeptName',
              label: '반',
              align: 'center',
              style: 'width: 100px',
              fix: true,
              sortable: false,
            },
            {
              name: 'deptName',
              field: 'deptName',
              label: '팀',
              align: 'center',
              fix: true,
              style: 'width: 100px',
              sortable: false,
            },
            {
              name: 'empNo',
              field: 'empNo',
              label: '사번',
              align: 'center',
              style: 'width:100px',
              fix: true,
              sortable: false,
            },
            {
              name: 'userName',
              field: 'userName',
              label: '성명',
              fix: true,
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            // {
            //   name: 'sexName',
            //   field: 'sexName',
            //   label: '성별',
            //   align: 'center',
            //   style: 'width:80px',
            //   sortable: false,
            // },
            {
              name: 'birthDate',
              field: 'birthDate',
              label: '생년월일',
              fix: true,
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'checkupDate',
              field: 'checkupDate',
              label: '검진일자',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'checkupType',
              field: 'checkupType',
              label: '검진구분',
              align: 'center',
              style: 'width:100px',
              sortable: false,
            },
            {
              name: 'hazardName',
              field: 'hazardName',
              label: '유해인자',
              align: 'center',
              style: 'width:200px',
              sortable: false,
            },
            {
              name: 'healthType',
              field: 'healthType',
              label: '건강구분',
              align: 'center',
              style: 'width:80px',
              sortable: false,
            },
            {
              name: 'checkupOpinion',
              field: 'checkupOpinion',
              label: '검진소견',
              align: 'left',
              style: 'width:200px',
              sortable: false,
            },
            {
              name: 'opinionDetailResult',
              field: 'opinionDetailResult',
              label: '소견 세부결과',
              align: 'left',
              style: 'width:500px',
              sortable: false,
            },
            {
              name: 'afterManage',
              field: 'afterManage',
              label: '사후관리소견',
              align: 'left',
              style: 'width:500px',
              sortable: false,
            },
            {
              name: 'suitableFlag',
              field: 'suitableFlag',
              label: '업무수행적합여부',
              align: 'center',
              style: 'width:80px',
              sortable: false,
            },
            // {
            //   name: 'suitableType',
            //   field: 'suitableType',
            //   label: '구분',
            //   align: 'center',
            //   style: 'width:80px',
            //   sortable: false,
            // },
            {
              name: 'remark',
              field: 'remark',
              label: '비고',
              align: 'left',
              style: 'width:1000px',
              sortable: false,
            },
          ],
          data: [],
          height: '800px',
        },
        mappingType: 'POST',
        listUrl: '',
        deleteUrl: '',
        popupOptions: {
          target: null,
          title: '',
          visible: false,
          width: '90%',
          param: {},
          closeCallback: null,
          isUpload: false,
          saveUploadUrl: '',
        },
      };
    },
    beforeCreate() {},
    created() {},
    beforeMount() {
    },
    mounted() {
      this.init();
    },
    beforeDestroy() {
    },
    computed: {
    },
    watch: {
    },
    methods: {
      init() {
        // role setting
        this.editable = this.$route.meta.editable;
        this.listUrl = selectConfig.hea.checkup.opinion.list.url;
        this.deleteUrl = transactionConfig.hea.checkup.opinions.delete.url;
        this.saveUploadUrl = transactionConfig.hea.opinion.resultUpload.url;
  
  
        this.getDetail();
      },
      getDetail() {
        if (this.checkUp.heaCheckupPlanId) {
          this.$http.url = this.$format(this.listUrl, this.checkUp.heaCheckupPlanId);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            this.grid.data = _result.data;
          },);
        } 
      },
      linkClick(result) {
        this.popupOptions.target = () => import(`${"./checkUpOpinionDetail.vue"}`);
        this.popupOptions.title = result.userName + '의 검진소견 상세';
        this.popupOptions.width = '70%';
        this.popupOptions.isFull = true;
        this.popupOptions.param = {
          heaCheckupResultId: result ? result.heaCheckupResultId : '',
        };
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closePopup;
      },
      closePopup() {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        this.getDetail();
      },
      openExcelUploader() {
        this.deleteTemps();
        this.popupOptions.title = '검진소견서 업로드'; // 검진소견서 업로드
        this.popupOptions.param = {
          hospitalId: this.checkUp.hospitalId,
          hospitalName: this.checkUp.hospitalName,
          plantCd: this.checkUp.plantCd,
        }
        this.popupOptions.target = () => import(`${'./checkUpResultOpinionUpload.vue'}`);
        this.popupOptions.isFull = true;
        this.popupOptions.width = '90%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeExcelUploadPopup;
      },
      closeExcelUploadPopup(_result) {
        this.popupOptions.target = null;
        this.popupOptions.visible = false;
        if (_result && _result.length > 0) {
          let saveData = {
            heaCheckupPlanId: this.checkUp.heaCheckupPlanId,
            heaCheckupResultId: uid(),
            regUserId: this.$store.getters.user.userId,
            chgUserId: this.$store.getters.user.userId,
          }
          this.$http.url = this.saveUploadUrl
          this.$http.type = 'POST';
          this.$http.param = saveData;
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.getDetail();
            this.deleteTemps();
          },);
        } else {
          this.deleteTemps();
        }
  
      },
      // 업로드용 Temp Data 일괄 삭제
      deleteTemps() {
        this.$http.url = transactionConfig.hea.opinion.tempUploadDelete.url;
        this.$http.type = 'DELETE';
        this.$http.request();
      },
      removeAll() {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: '삭제하시겠습니까? <br> (관련된 유소견자 및 유소견자별 상담관리가 모두 삭제 됩니다.)',
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl, this.checkUp.heaCheckupPlanId);
            this.$http.type = 'DELETE';
            this.$http.request(() => {
              this.getDetail();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    }
  };
  </script>